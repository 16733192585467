import {
  DataTable,
  Pagination,
  TableHeader,
} from "../../../components/table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  Box,
  Center,
  Text,
  useColorModeValue,
  Badge,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import NetworkWrapper from "../../../components/wrapper/NetworkWrapper";
import dayjs from "../../../components/atom/dayjs";
import filters from "./filter";
import { Header } from "../../../components/table/CaseListingTableComponent";
import { useUrlParams } from "../../../hooks/action/useUtils";
import {
  useEntityEventApi,
  useEntityFilter,
  useEntityServiceApi,
} from "../../../hooks/api/useEntityApi";
import { useNavigate } from "react-router-dom";
import {
  objectToQueryString,
  objectToQueryStringOmitEmpty,
} from "../../../utils";
import { lazy, Suspense } from "react";
import { Loader } from "../../Navigation";
const ReactJson = lazy(() => import("react-json-view"));

export default function EntityTracing() {
  const navigate = useNavigate();

  const { data: entityService, ...entityServiceResp }: any =
    useEntityServiceApi();
  const { data: entityEvent, ...entityEventResp }: any = useEntityEventApi();
  const { data: entityFilter, rest, meta, setPage } = useEntityFilter({});

  const { getParams, getAllParams } = useUrlParams();

  const columnHelper = createColumnHelper<any>();
  const darkSlate = useColorModeValue("slate.200", "slate.800");

  const columns = [
    columnHelper.accessor("eventName", {
      cell: (info) => (
        <Text whiteSpace={"nowrap"} ml={3} fontWeight={"semibold"}>
          {info.getValue()}
        </Text>
      ),
      header: () => <Header text={"*️⃣ Event Name"} />,
    }),
    columnHelper.accessor("serviceNameText", {
      cell: (info) => (
        <Badge borderRadius={"full"} px={3} colorScheme="primary">
          <Text>{info.getValue()}</Text>
        </Badge>
      ),
      header: () => <Header text={"#️⃣ Service Name"} />,
    }),
    columnHelper.accessor("entityId", {
      cell: (info) => (
        <Box px={3} borderRadius={"full"}>
          <Text fontWeight={"normal"} textTransform={"capitalize"}>
            {info.getValue()}
          </Text>
        </Box>
      ),
      header: () => <Text align={"left"}> 🖇️ Traces </Text>,
    }),

    columnHelper.accessor("data", {
      cell: (info) => (
        <Box>
          <Text>{JSON.parse(info.getValue())?.message}</Text>
        </Box>
      ),
      header: () => <Text align={"left"}>Message</Text>,
    }),

    columnHelper.accessor("timestamp", {
      cell: (info) => (
        <Box bg={darkSlate} px={3} borderRadius={"full"}>
          <Text whiteSpace={"nowrap"}>
            {dayjs.unix(info.getValue() / 1000).format("DD-MM-YYYY hh:mm A")}
          </Text>
        </Box>
      ),
      header: () => <Text align={"left"}>Time Stamp </Text>,
    }),
    columnHelper.accessor("data", {
      cell: (info) => <CodeRender code={info.getValue()} />,
      header: () => <Text align={"left"}>Data</Text>,
    }),
  ];

  const onServiceSelect = (serviceName: any) => {
    const payload = {
      ...getAllParams(),
      serviceNameText: serviceName?.value || null,
    };
    const query = objectToQueryStringOmitEmpty(payload);
    navigate({
      pathname: window.location.pathname,
      search: query,
    });
  };

  const onEventSelect = (eventName: any) => {
    const payload = { ...getAllParams(), eventName: eventName?.value || null };
    const query = objectToQueryStringOmitEmpty(payload);
    navigate({
      pathname: window.location.pathname,
      search: query,
    });
  };

  return (
    <Center h={"100vh"} pt={10}>
      <NetworkWrapper>
        <Box maxW={["95vw", "80vw"]} w={"full"} overflow={"hidden"}>
          {filters && (
            <Box
              zIndex={200}
              bg={"white"}
              w="full"
              mt={[32, "auto"]}
              mb={[4, "auto"]}
            >
              <TableHeader
                id="traces"
                valueOfId={getParams("traces") || ""}
                dropdown1={{
                  options:
                    entityService?.data?.map((itm: string) => ({
                      label: itm?.split("_").join(" ")?.toLowerCase(),
                      value: itm,
                    })) || [],
                  defaultInputValue:
                    getParams("serviceNameText")
                      ?.split("_")
                      .join(" ")
                      ?.toLowerCase() || "",
                  placeholder: "Please Select Services",
                  isLoading: entityServiceResp.isLoading,
                  onChange: (e) => onServiceSelect(e),
                  isClearable: true,
                }}
                dropdown2={{
                  options:
                    entityEvent?.data?.map((itm: string) => ({
                      label: itm?.split("_").join(" ")?.toLowerCase(),
                      value: itm,
                    })) || [],
                  defaultInputValue:
                    getParams("eventName")
                      ?.split("_")
                      .join(" ")
                      ?.toLowerCase() || "",
                  placeholder: "Please Select Event",
                  isLoading: entityEventResp.isLoading,
                  onChange: (e) => onEventSelect(e),
                  isClearable: true,
                }}
              />
            </Box>
          )}
          <Box
            borderRadius={"md"}
            maxH={["90vh", "85vh"]}
            maxW={["95vw", "80vw"]}
            minW={["95vw", "80vw"]}
            overflow={"scroll"}
            bg={"white"}
            shadow={"2xl"}
            className="custom-scrollbar"
          >
            <DataTable
              isDataLoading={rest.isLoading || rest.isLoading}
              columns={columns}
              data={entityFilter || []}
            />
          </Box>
        </Box>
        <Box my={5}>
          <Pagination
            currentPage={meta.pageIndex || 1}
            totalPages={meta.pageCount}
            maxVisiblePages={meta.pageCount > 3 ? 5 : 3}
            onPageChange={(pg) => setPage(pg || 1)}
          />
        </Box>
      </NetworkWrapper>
    </Center>
  );
}

export function CodeRender({ code }: { code: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const options = {
  //   expandLevel: 10,
  // }
  // const palette = {}
  // const styles = {}

  return (
    <>
      <Button
        rounded={"full"}
        colorScheme="orange"
        minW={100}
        size={"sm"}
        onClick={onOpen}
        fontWeight={"semibold"}
      >
        Preview
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Box p={0} borderRadius={"md"} overflow={"hidden"}>
              <Suspense
                fallback={
                  <Center>
                    <Loader />
                  </Center>
                }
              >
                <ReactJson
                  style={{ padding: 10 }}
                  displayDataTypes={false}
                  theme={"hopscotch"}
                  src={JSON.parse(code)}
                />
              </Suspense>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
